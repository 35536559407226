import React from "react";
import { auth } from "../src/db/firebase";
import NavBar from "./Generics/NavBar";

const App = () => {  
    const [firebaseUser, setFirebaseUser] = React.useState(false)

    React.useEffect(() => {
        auth.onAuthStateChanged(user => {
            console.log("Usuario desde el useeffect " + user)
            if(user){
                setFirebaseUser(user)
            }else{
                setFirebaseUser(null)
            }
        })
        console.log("Desde APP");
    }, [])

  return (    
    <NavBar firebaseUser={firebaseUser} />
  )
}


export default App;

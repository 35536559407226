import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  useHistory,
  Route,
  NavLink
} from "react-router-dom";
import Login from "../Auth/Login";
import { auth } from "../db/firebase";
import Home from '../Generics/Home';
import Precios from "../Generics/Precios";

const NavBar = ( props ) => {
    let history = useHistory();
    
    useEffect(() => {
      const user = auth.currentUser;
  
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // ...
        console.log("Logueado");
      } else {
        console.log("Sin Loguear");
      }
    }, [])
  
    const logout = () => {
      // console.log(auth.currentUser); 
      try {
        auth.signOut();
        history.push("/Home");
      } catch (error) {
  
      } finally {
      }
    }
  
    return (
      <Router>
        <div>

        <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
            <div class="container-fluid">                
                <a class="navbar-brand" href="/Home">PreciosQRO</a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <NavLink activeClassName="active" className="nav-link" to="/Home">Home</NavLink>                    
                    </li>
                    <li class="nav-item">
                        <NavLink activeClassName="active" className="nav-link" to="/Precios">Precios</NavLink>                    
                    </li>
                    <li class="nav-item">
                        <NavLink activeClassName="active" className="nav-link" to="/Usuario">Usuario</NavLink>                    
                    </li>
                </ul>
                <div className="d-flex">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        {
                            props.firebaseUser == null ? (
                                <li class="nav-item">
                                    <NavLink activeClassName="active" className="nav-link" to="/Login">Login</NavLink>
                                </li>
                            ) : null
                        }
                        {
                            props.firebaseUser !== null ? (
                                <li class="nav-item">
                                    <NavLink activeClassName="active" className="nav-link" to="/LogOut">LogOut</NavLink>
                                </li>
                            ) : null
                        }
                    </ul>
                </div>
                {/* <form class="d-flex">
                    <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                    <button class="btn btn-outline-success" type="submit">Search</button>
                </form> */}
                </div>
            </div>
        </nav>
          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
          <Switch>
            <Route exact path="/Login">
                {
                    props.firebaseUser == null ? (
                        <Login />
                    ) : <Home />
                }
            </Route>
            <Route exact path="/Precios">
              <Precios usuario = { auth.currentUser }/>
            </Route>
            <Route exact path="/Home">
              <Home />
            </Route>
            <Route exact path="/LogOut">
                {
                    props.firebaseUser !== null ? (
                        () => logout()
                    ) : <Home />
                }
            </Route>
            <Route exact path="/Usuario">
              {() => console.log(props.firebaseUser)}
            </Route>
  
          </Switch>
        </div>
      </Router>
    );
}

export default NavBar

import React, { useCallback, useState } from "react";
import { auth } from "../db/firebase";
import { useHistory } from "react-router-dom";

const Login = () => {
    let history = useHistory();    

    const [email, setEmail] = useState('')
    const [password, setpassword] = useState('')

    const emailChange = (e) => {
        setEmail(e.target.value)
    }

    const passwordChange = (e) => {
        setpassword(e.target.value)
    }

    const ingresar = useCallback(
        async () => {
            try {                
                await auth.signInWithEmailAndPassword(email, password)
                    .then((userCredential) => {
                        // Signed in
                        var user = userCredential.user;
                        console.log("******Login******             " + user);
                        history.push("/Precios")

                        // ...
                    })
                    .catch((error) => {
                        //   var errorCode = error.code;
                        //   var errorMessage = error.message;
                    });
            } catch (error) {

            }
        },
        [history, email, password],
    )

    return (
        <>
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-9 col-md-7 text-center text-white bg-dark">
                        <div className="row  d-flex justify-content-center">
                            <h1 className="display-4 mt-5">Login</h1>
                            <p className="fs-3">Ingresa tu usuario y contraseña</p>
                            <div className="my-5 col-12 col-md-8">
                                <input type="text"
                                    className="form-control"
                                    placeholder="email"
                                    value={email}
                                    onChange={(e) => emailChange(e)}
                                />
                                <input type="text"
                                    className="form-control mt-2"
                                    placeholder="password"
                                    value={password}
                                    onChange={(e) => passwordChange(e)}
                                />
                                <button className="btn btn-warning form-control mb-5 mt-2 "
                                    onClick={() => ingresar()}
                                >Enviar</button>
                            </div>
                        </div>
                    </div>
                    <div className="d-none d-md-block col-md-4 text-center bg-primary text-white">
                        <h1 className="display-6 mt-2">Bienvenido</h1>
                        <img className="img-fluid" src="img/Logo.png" alt="" />
                        <p className="mt-2">Consulta de precios</p>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Login

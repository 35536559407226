import React from 'react'
import { auth } from '../db/firebase'
import { useHistory } from "react-router-dom";
import { useState } from 'react';
import { InventarioSearch } from './InventarioSearch';

const Precios = ( props ) => { 
    let history = useHistory();    

    
    const [Busqueda, setBusqueda] = useState('')
    const [SearchItem, setSearchItem] = useState('xxxx')
    
    const HandleText = (e) => {
        setBusqueda(e.target.value);
    }
    
    if(!auth.currentUser) { 
        history.push("/Login")
    }

    try {
        console.log(props.usuario.email);        
    } catch (error) {
        
    }
    
    return (
    <form>
      <div className="container form col-12" style={{paddingTop: "25px"}}>
        <h2 className="animate__animated  animate__bounce">Buscar producto</h2>

        <input 
          type="text" 
          className="form-control form"
          value= { Busqueda }
          onChange = { HandleText }
        ></input>
        <button 
          className="button form-control"          
          onClick = { (e) => { e.preventDefault(); setSearchItem(Busqueda) }}        
        >
        Buscar
        </button>
        <InventarioSearch descripcion= { SearchItem } usuario = { props.usuario }  />  
      </div>

    </form>
    )
}

export default Precios

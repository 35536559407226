import React, { useEffect, useState } from 'react'
import Inventario from '../db/inv.json'

export const InventarioSearch = ({ descripcion, usuario }) => {

    const [Search, setSearch] = useState('')
    const [data, setData] = useState([])    

    useEffect(() => {
        setData(Inventario.map((i) => { return i }))
    }, []);

    let filtro = data.filter(i => i.DESCRIPCION.includes(descripcion.toUpperCase()));

    if(!filtro.length > 0){
        filtro = data.filter(i => i.CLU===descripcion);
    }

    console.log(filtro)

    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      
    formatter.format(2500); /* $2,500.00 */

    return (
        <>{
            filtro.map((i) => {
                return (
                    <div className="card" style={{ marginTop: "20px", alignItems: "center" }}>
                        <img className="card-img-top img-fluid animate__animated animate__zoomIn" src={ './images/' + i.CLU + '.png' } 
                            onError={(e) => {e.target.src = './images/default.png' ; e.target.onError = null;}} 
                            style={{ height: "500px", width: "500px" }} alt="Card image cap"></img>
                        <div className="card-body"  style={{ alignItems:'center'}}>
                            <h4 className="card-title">{i.DESCRIPCION}</h4>                            
                            <h5 className="card-title" style={{ alignSelf:'center'}}>CLU: {i.CLU}</h5>
                            {
                                usuario.email==='fausto.herrera@weberqro.com.mx' ? 
                                    <p className="card-text" style={{ color: "red", fontSize: "1.5rem", fontWeight: "bold", alignSelf:"center !important"}}>COSTO: {formatter.format(i.COST)}</p>
                                :
                                    null

                            }
                            <p className="card-text" style={{ color: "red", fontSize: "1.5rem", fontWeight: "bold", alignSelf:"center !important"}}>Precio: {formatter.format(i.PRICE)}</p>
                        </div>
                    </div>

                )
            })
        }


        </>
    )
}

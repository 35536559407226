import app from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyAELgkfTiNYWCYNi7H9Z5Fm_881oJyWaRE",
    authDomain: "crud-firebase-bc6ca.firebaseapp.com",
    projectId: "crud-firebase-bc6ca",
    storageBucket: "crud-firebase-bc6ca.appspot.com",
    messagingSenderId: "108788260106",
    appId: "1:108788260106:web:ffea3cdbe2d801d4e2eef3"
  };
  // Initialize Firebase
app.initializeApp(firebaseConfig);

const db = app.firestore();
const auth = app.auth();

export { db, auth }
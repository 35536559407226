import React from 'react'
// import { auth } from '../db/firebase'

const Home = () => {
   
    return (
        <>
            <div className="container"> 
                <h2>Home</h2>   
            </div>
        </>
    )
}

export default Home
